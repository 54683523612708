<template>
  <div class="resultTable">
    <sui-container>
      <!-- Header -->
      <div class="ui grid">
        <div class="row">
          <div class="column resultHeader">
            {{ $t("result.resultTable.heading.mainHeader") }}:
          </div>
        </div>
        <div class="row">
          <div class="eleven wide column resultDesc">
            {{ $t("result.resultTable.heading.subHeader") }}
          </div>
          <div class="right floated right aligned five wide column">
            <router-link :to="{ name: 'home' }">
              <button class="ui tiny secondary button" @click="newProject()">
                {{ $t("result.resultTable.heading.newProject") }}
              </button>
            </router-link>
            <router-link :to="{ name: 'home' }">
              <button class="ui tiny secondary button" @click="returnToEdit()">
                {{ $t("result.resultTable.heading.returnButton") }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <!-- Table -->
      <table class="ui tablet stackable basic table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("result.resultTable.table.room") }}</th>
            <th v-html="$t('result.resultTable.table.area')"></th>
            <th v-html="$t('result.resultTable.table.volume')"></th>
            <th v-html="$t('result.resultTable.table.airStream')"></th>
            <th>{{ $t("result.resultTable.table.type") }}</th>
            <th>{{ $t("result.resultTable.table.exchange") }}</th>
            <th>{{ $t("result.resultTable.table.diffusers") }}</th>
            <th>{{ $t("result.resultTable.table.cables") }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(room, index) in rooms">
            <tr :key="index">
              <td><span class="mobile">#</span> {{ index + 1 }}</td>
              <td>
                <span class="mobile"
                  >{{ $t("result.resultTable.table.room") }}:
                </span>
                {{ room.name }}
              </td>
              <td>
                <span
                  class="mobile"
                  v-html="$t('result.resultTable.table.area')"
                  >: </span
                >{{ replaceWithComma(room.area) }}
              </td>
              <td>
                <span
                  class="mobile"
                  v-html="$t('result.resultTable.table.volume')"
                  >: </span
                >{{ replaceWithComma(room.volume) }}
              </td>
              <td>
                <span
                  class="mobile"
                  v-html="$t('result.resultTable.table.airStream')"
                  >: </span
                >{{ room.air_stream }}
              </td>
              <td>
                <span class="mobile"
                  >{{ $t("result.resultTable.table.type") }}: </span
                >{{ $t("rooms.list.table." + room.room_type) }}
              </td>
              <td>
                <span class="mobile"
                  >{{ $t("result.resultTable.table.exchange") }}: </span
                >{{ replaceWithComma(room.fold_exchange) }}
              </td>
              <td>
                <span class="mobile"
                  >{{ $t("result.resultTable.table.diffusers") }}: </span
                >{{ room.diffusers_amount }}
              </td>
              <td>
                <span class="mobile"
                  >{{ $t("result.resultTable.table.cables") }}: </span
                >{{ room.wires_amount }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr class="footRow">
            <td colspan="9">
              {{ $t("result.resultTable.table.totalSupply") }}:
              {{ totalSupply }} m<sup>3</sup>/h
            </td>
          </tr>
          <tr class="footRow">
            <td colspan="9">
              {{ $t("result.resultTable.table.totalExhaust") }}:
              {{ totalExhaust }} m<sup>3</sup>/h
            </td>
          </tr>
        </tfoot>
      </table>
      <div style="margin-bottom: 25px;" class="ui stackable">
        <div class="row">
          <p>{{ $t("result.resultTable.annotation") }}</p>
        </div>
      </div>
      <!--  type of approach section -->
      <div class="ui stackable equal width grid approachSection">
        <div class="row">
          <div class="column resultHeader">
            {{
              supplyConnectorToggle || exhaustConnectorToggle
                ? $t("result.resultTable.approachSection.headerConnectors")
                : $t("result.resultTable.approachSection.header")
            }}
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div>
              <b>{{
                $t("result.resultTable.approachSection.labelSupplyBox")
              }}</b>
            </div>
            <sui-dropdown
              fluid
              :options="optionsBox"
              placeholder=""
              search
              selection
              v-model="supplyBox"
            ></sui-dropdown>
          </div>
          <div class="column leftSection" v-show="supplyConnectorToggle">
            <div>
              <b>{{
                $t("result.resultTable.approachSection.labelSupplyConnector")
              }}</b>
            </div>
            <sui-dropdown
              placeholder=""
              fluid
              selection
              :options="optionsConnector"
              v-model="supplyConnector"
            >
            </sui-dropdown>
          </div>
          <div class="right floated column rightSection">
            <div class="rightSectionHead">
              <b>{{
                $t("result.resultTable.approachSection.labelExhaustBox")
              }}</b>
            </div>
            <sui-dropdown
              fluid
              selection
              :options="optionsBox"
              v-model="exhaustBox"
            >
            </sui-dropdown>
          </div>
          <div class="column" v-show="exhaustConnectorToggle">
            <div>
              <b>{{
                $t("result.resultTable.approachSection.labelExhaustConnector")
              }}</b>
            </div>
            <sui-dropdown
              fluid
              selection
              :options="optionsConnector"
              v-model="exhaustConnector"
            ></sui-dropdown>
          </div>
        </div>
          <div class="row">
              <div class="column">
                  <div>
                      <b>{{
                              $t("result.resultTable.approachSection.pipeDiameter")
                          }}</b>
                  </div>
                  <sui-dropdown
                      fluid
                      :options="optionsPipeDiameter"
                      placeholder=""
                      search
                      selection
                      v-model="pipeDiameter"
                  ></sui-dropdown>
              </div>
          </div>
      </div>
    </sui-container>
  </div>
</template>

<script>
export default {
  name: "ResultTable",
  data() {
    return {
      rooms: [],
      connectionParams: [],
      totalSupply: 0,
      totalExhaust: 0,
        pipeDiameter: "200",
      supplyBox: null,
      supplyConnector: null,
      supplyConnectorToggle: false,
      exhaustBox: null,
      exhaustConnector: null,
      exhaustConnectorToggle: false,
      optionsBox: [
        { text: this.$t("side"), value: "side", key: "side" },
        { text: this.$t("top"), value: "top", key: "top" }
      ],
      optionsConnector: [
        { text: this.$t("horizontally"), value: "horizontally", key: "horizontally" },
        { text: this.$t("vertically"), value: "vertically", key: "vertically" },
        { text: this.$t("none"), value: "none", key: "none" }
      ],
        optionsPipeDiameter: [
            {text: "125", value: "125", key: "125"},
            {text: "160", value: "160", key: "160"},
            {text: "200", value: "200", key: "200"}
        ],
      products: []
    };
  },
  mounted() {
    this.rooms = this.$store.state.rooms.roomsWithItems;
    if (this.rooms.length === 0) {
      this.$router.push({
        name: "home"
      });
    }
    this.connectionParams = this.$store.state.rooms.connectionParams;
    this.totalSupply = this.$store.getters["rooms/getTotalSupply"];
    this.totalExhaust = this.$store.getters["rooms/getTotalExhaust"];

    this.$store.state.orders.canSaveOrder = false;

      let recuperatorAmount = this.totalSupply;
      if (this.totalSupply > 500) {
         recuperatorAmount = Math.ceil(((this.totalSupply * 1.2)/50) * 50);
      }

      if(recuperatorAmount < 250) {
          this.pipeDiameter = "125"
      } else if(recuperatorAmount >=250 && recuperatorAmount < 400) {
          this.pipeDiameter = "160";
      } else if(recuperatorAmount >= 400) {
          this.pipeDiameter = "200";
      }
      this.toggleConnector();
  },
  methods: {
      toggleConnector() {
        let spigotSupply = this.connectionParams.spigot_supply;
        let spigotExhaust = this.connectionParams.spigot_exhaust;

        if(spigotExhaust < 7) {
            this.exhaustConnectorToggle = false;
        } else if(spigotExhaust >= 7 && spigotExhaust <= 10) {
            if(this.exhaustBox === 'side') {
                this.exhaustConnectorToggle = true;
            } else {
                this.exhaustConnectorToggle = false;
            }
        } else {
            this.exhaustConnectorToggle = true;
        }

        if(spigotSupply < 7) {
            this.supplyConnectorToggle = false;
        } else if(spigotSupply >= 7 && spigotSupply < 10) {
            if(this.supplyBox === 'side') {
                this.supplyConnectorToggle = true;
            } else {
                this.supplyConnectorToggle = false;
            }
        } else {
            this.supplyConnectorToggle = true;
        }
      },
    getProducts() {
      let sendQuery = false;
      if (this.supplyBox && this.exhaustBox) {
        sendQuery = true;
        if (
          (this.supplyConnectorToggle && this.supplyConnector) ||
          !this.supplyConnectorToggle
        ) {
          sendQuery = true;
        } else {
          sendQuery = false;
        }
        if (
          (this.exhaustConnectorToggle && this.exhaustConnector) ||
          !this.exhaustConnectorToggle
        ) {
          sendQuery = true;
        } else {
          sendQuery = false;
        }
      } else {
        sendQuery = false;
      }

      if (sendQuery) {
        this.$store.state.orders.canSaveOrder = true;
        this.connectionParams.exhaust_connection = this.exhaustBox;
        this.connectionParams.supply_connection = this.supplyBox;
        this.connectionParams.exhaust_connection_type = this.exhaustConnector;
        this.connectionParams.supply_connection_type = this.supplyConnector;
        this.connectionParams.pipeDiameter = this.pipeDiameter;
        this.$store.dispatch(
          "rooms/calculateConnectionsItems",
          this.connectionParams
        );
      }
    },
    replaceWithComma(string) {
      let floatNumber = parseFloat(string).toFixed(2);
      string = floatNumber.toString();
      return string.replace(".", ",");
    },
    returnToEdit() {
      this.$store.dispatch("rooms/updateErrors", "");
      this.rooms = "";
    },
    newProject() {
      this.$store.dispatch("rooms/updateRoomsList", []);
      this.$store.dispatch("rooms/setHeight", 250);
    }
  },
  watch: {
    supplyBox() {
          this.toggleConnector();
      this.getProducts();
    },
    supplyConnector() {
      this.getProducts();
    },
    exhaustBox() {
        this.toggleConnector();
      this.getProducts();
    },
    exhaustConnector() {
      this.getProducts();
    },
      pipeDiameter() {
        this.getProducts()
      }
  },
};
</script>
