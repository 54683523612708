<template>
  <div
    class="backgroundBanner result"
    :style="{
      'background-image': 'url(' + require('@/assets/images/textBg.png') + ')'
    }"
  >
    <sui-container>
      <div class="ui grid equal width">
        <div class="row centered">
          <div class="column fluid">
            {{ $t("result.banner.contents") }}
          </div>
        </div>
        <div class="stackable row">
          <div class="three wide column first">
            <strong> {{ $t("result.banner.nameLabel") }}:</strong>
          </div>
          <div class="three wide column second">
            <sui-input
              v-model="projectData.name"
              :placeholder="$t('result.banner.nameLabel')"
              focus
              v-on:input="dispatchName"
              maxlength="50"
            />
          </div>
          <div class="three wide column third">
            <sui-button
              ui
              fluid
              inverted
              button
              v-on:click="saveProject"
              :disabled="!canSaveOrder || !projectData.name || loading"
              @change="setOrderData"
              :loading="loading"
            >
              {{ $t("result.banner.saveButton") }}
            </sui-button>
          </div>
        </div>
        <!--        <div class="stackable row">-->
        <!--          <div class="two wide column first">-->
        <!--            <strong> {{ $t("result.banner.address") }}:</strong>-->
        <!--          </div>-->
        <!--          <div class="two wide column second">-->
        <!--            <sui-input-->
        <!--                    v-model="projectData.street"-->
        <!--                    :placeholder="$t('result.banner.street')"-->
        <!--                    focus-->
        <!--                    @change="setOrderData"-->
        <!--                    v-on:input="dispatchStreet"-->
        <!--                    maxlength="70"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div class="two wide column second">-->
        <!--            <sui-input-->
        <!--                    v-model="projectData.city"-->
        <!--                    :placeholder="$t('result.banner.city')"-->
        <!--                    focus-->
        <!--                    @change="setOrderData"-->
        <!--                    v-on:input="dispatchCity"-->
        <!--                    maxlength="70"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div class="two wide column second">-->
        <!--            <sui-input-->
        <!--                    v-model="projectData.zipCode"-->
        <!--                    :placeholder="$t('result.banner.zipCode')"-->
        <!--                    focus-->
        <!--                    @change="setOrderData"-->
        <!--                    v-on:input="dispatchPostcode"-->
        <!--                    maxlength="10"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div class="two wide column first">-->
        <!--            <strong> {{ $t("result.banner.investor") }}:</strong>-->
        <!--          </div>-->
        <!--          <div class="three wide column second">-->
        <!--            <sui-input-->
        <!--                    v-model="projectData.investor"-->
        <!--                    :placeholder="$t('result.banner.investor')"-->
        <!--                    focus-->
        <!--                    @change="setOrderData"-->
        <!--                    v-on:input="dispatchInvestor"-->
        <!--                    maxlength="70"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </sui-container>
    <sui-modal v-model="projectSavedModal" size="mini">
      <sui-modal-content>
        <div class="modal-top-bar">
          <div class="text">{{ $t("result.banner.saved") }}</div>
          <div class="cross" v-on:click="closeModal">x</div>
        </div>
        <h4 style="color: rgba(0,0,0, 0.87);">
          {{ $t("result.banner.projectSaved") }}
        </h4>
        <p style="color: rgba(0,0,0, 0.87);">
          {{ $t("result.banner.modal.nowYouCanGo") }}
          <strong
            ><router-link style="color: rgba(0,0,0, 0.87);" to="profile"
              >{{ $t("result.banner.modal.profile") }}</router-link
            ></strong
          >
          {{ $t("result.banner.modal.toSeeProjects") }}.
        </p>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Banner",
  components: {},
  data() {
    return {
      loggedIn: this.$store.state.users.isLoggedIn,
      projectData: {
        name: "",
        street: "",
        city: "",
        zipCode: "",
        investor: ""
      },
      canSaveOrder: this.$store.state.orders.canSaveOrder,
      projectSavedModal: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    loggedInChanged() {
      return this.$store.state.users.isLoggedIn;
    },
    canSaveOrderChanged() {
      return this.$store.state.orders.canSaveOrder;
    },
    ...mapState({
      loading: state => {
        return state.orders.loading;
      }
    })
  },
  watch: {
    loggedInChanged(value) {
      this.loggedIn = value;
    },
    canSaveOrderChanged(value) {
      this.canSaveOrder = value;
    }
  },
  methods: {
    closeModal: function() {
      this.projectSavedModal = false;
    },
    dispatchName: function() {
      this.$store.dispatch("orders/setProjectName", this.projectData.name);
    },
    dispatchStreet: function() {
      this.$store.dispatch("orders/setStreet", this.projectData.street);
    },
    dispatchCity: function() {
      this.$store.dispatch("orders/setCity", this.projectData.city);
    },
    dispatchPostcode: function() {
      this.$store.dispatch("orders/setPostcode", this.projectData.zipCode);
    },
    dispatchInvestor: function() {
      this.$store.dispatch("orders/setInvestor", this.projectData.investor);
    },
    saveProject() {
      if (this.loggedIn) {
        this.$store
          .dispatch("orders/saveProject", this.projectData)
          .then(() => {
            this.projectSavedModal = true;
            this.projectNameModal = false;
          });
      } else {
        this.$store.dispatch("users/toggleModal", true);
      }
    },
    setOrderData: function() {
      this.$store.dispatch("rooms/setOrderData", this.projectData);
    }
  },
  created() {}
};
</script>
