<template>
  <div>
      <Banner />
      <ResultTable />
      <ProductsList />
  </div>
</template>

<script>
import Banner from "@/components/result/Banner.vue";
import ResultTable from "@/components/result/ResultTable.vue";
import ProductsList from "@/components/result/ProductsList.vue";
export default {
  name: "Projectpage",
  data() {
    return {};
  },
  mounted() {},
  components: {
    Banner,
    ResultTable,
    ProductsList
  }
};
</script>
