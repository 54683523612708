<template>
    <div class="productsList" v-show="products.length || removedOrOptionalProducts.length">
        <sui-container>
            <sui-grid v-show="products.length">
                <sui-grid-row :columns="2">
                    <sui-grid-column>
                        <div class="ui stackable grid">
                            <div class="row">
                                <div class="column resultHeader">
                                    <h4>
                                        <b>{{ $t("result.productsList.header") }}:</b>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </sui-grid-column>
                    <sui-grid-column text-align="right" v-if="loggedIn && priceSettings">
                        <div>
                            <h4>{{ $t("result.productsList.price") }}:</h4>
                            <sui-checkbox
                                @change="priceChange"
                                toggle
                                v-model="priceEnable"
                            />
                        </div>
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>
            <!-- Table -->
            <table class="ui tablet  striped stackable table" v-show="products.length">
                <thead>
                <tr>
                    <th></th>
                    <th>{{ $t("result.productsList.table.number") }}</th>
                    <th>{{ $t("result.productsList.table.name") }}</th>
                    <th>{{ $t("result.productsList.table.qty") }}</th>
                    <th v-if="priceEnable">
                        {{ $t("result.productsList.table.price") }} [{{
                            currency
                        }}/{{ $t("result.productsList.table.price_pcs") }}]
                    </th>
                    <th v-if="priceEnable">
                        {{ $t("result.productsList.table.price_sum") }} [{{ currency }}]
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(product, index) in products">
                    <tr :key="index">
                        <td>
                            <div class="ui grid">
                                <div class="tablet computer only row">
                                    <VuePureLightbox
                                        v-bind:thumbnail="product.image"
                                        class="ui mini image product-image"
                                        :images="[product.image]"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                <span class="mobile"
                >{{ $t("result.productsList.table.number") }}:</span
                >
                            {{ product.catalog_numer }}
                        </td>
                        <td>
                <span class="mobile"
                >{{ $t("result.productsList.table.name") }}:</span
                >
                            <span v-html="product.name"></span>
                            <span v-if="product.estimated">*</span>
                            <sui-popup
                                position="top left"
                                v-show="product.annotation && product.annotation.length > 0"
                            >
                                <sui-icon
                                    v-show="product.annotation && product.annotation.length > 0"
                                    style="margin-left: 10px"
                                    slot="trigger"
                                    name="info"
                                    color="black"
                                    circular
                                />
                                {{ product.annotation }}
                            </sui-popup>
                        </td>
                        <td>
                <span class="mobile"
                >{{ $t("result.productsList.table.qty") }}:</span
                >
                            <sui-input type="number" min="1" max="9999" step="1" v-model="products[index].quantity"
                                       @change="itemQuantity(index)"/>
                        </td>
                        <td v-if="priceEnable">
                <span class="mobile"
                >{{ $t("result.productsList.table.price") }}:</span
                >
                            {{ product.price }}
                        </td>
                        <td v-if="priceEnable">
                <span class="mobile"
                >{{ $t("result.productsList.table.price_sum") }}[{{ currency }}]:</span
                >
                            {{ product.price_sum }}
                        </td>
                        <td>
                            <sui-button
                                size="tiny"
                                icon="minus"
                                @click="removeProduct(index)"
                            ></sui-button>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>


            <sui-grid v-if="removedOrOptionalProducts.length > 0">
                <sui-grid-row :columns="1">
                    <sui-grid-column>
                        <div class="ui stackable grid">
                            <div class="row">
                                <div class="column resultHeader">
                                    <h4>
                                        <b>{{ $t("result.productsList.header_optional") }}:</b>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>

            <table class="ui tablet  striped stackable table" v-if="removedOrOptionalProducts.length > 0">
                <thead>
                <tr>
                    <th></th>
                    <th>{{ $t("result.productsList.table.number") }}</th>
                    <th>{{ $t("result.productsList.table.name") }}</th>
                    <th>{{ $t("result.productsList.table.qty") }}</th>
                    <th v-if="priceEnable">
                        {{ $t("result.productsList.table.price") }} [{{
                            currency
                        }}/{{ $t("result.productsList.table.price_pcs") }}]
                    </th>
                    <th v-if="priceEnable">
                        {{ $t("result.productsList.table.price_sum") }} [{{ currency }}]
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(product, index) in removedOrOptionalProducts">
                    <tr :key="index">
                        <td>
                            <div class="ui grid">
                                <div class="tablet computer only row">
                                    <VuePureLightbox
                                        v-bind:thumbnail="product.image"
                                        class="ui mini image product-image"
                                        :images="[product.image]"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                <span class="mobile"
                >{{ $t("result.productsList.table.number") }}:</span
                >
                            {{ product.catalog_numer }}
                        </td>
                        <td>
                <span class="mobile"
                >{{ $t("result.productsList.table.name") }}:</span
                >
                            <span v-html="product.name"></span>
                            <span v-if="product.estimated">*</span>
                            <sui-popup
                                position="top left"
                                v-show="product.annotation && product.annotation.length > 0"
                            >
                                <sui-icon
                                    v-show="product.annotation && product.annotation.length > 0"
                                    style="margin-left: 10px"
                                    slot="trigger"
                                    name="info"
                                    color="black"
                                    circular
                                />
                                {{ product.annotation }}
                            </sui-popup>
                        </td>
                        <td>
                <span class="mobile"
                >{{ $t("result.productsList.table.qty") }}:</span
                >
                            <sui-input type="number" min="1" max="9999" step="1"
                                       v-model="removedOrOptionalProducts[index].quantity"
                                       />
                        </td>
                        <td v-if="priceEnable">
                <span class="mobile"
                >{{ $t("result.productsList.table.price") }}:</span
                >
                            {{ product.price }}
                        </td>
                        <td v-if="priceEnable">
                <span class="mobile"
                >{{ $t("result.productsList.table.price_sum") }}[{{ currency }}]:</span
                >
                            {{ product.price_sum }}
                        </td>
                        <td>
                            <sui-button
                                size="tiny"
                                @click="addProduct(index)"
                            >{{ $t("result.productsList.table.back") }}
                            </sui-button>
                        </td>
                        <td></td>
                    </tr>
                </template>
                </tbody>
            </table>

            <h3 style="text-align: right" v-if="priceEnable">
                {{ $t("result.productsList.sum") }}:
                <strong>{{ getPricesSum }}</strong>
                {{ $t("result.productsList.sum_label") }} [{{ currency }}]
            </h3>
            <p class="estimated-annotation">
                {{ $t("result.productsList.estimated") }}
            </p>
            <!-- Save project -->
            <div class="ui equal width grid" id="resultTable">
                <div class="row centered">
                    <div class="twelve wide column center aligned">
                        <p>{{ $t("result.productsList.saveProject.text") }}</p>
                    </div>
                </div>
                <div class="row stackable centered">
                    <div class="four wide column center aligned saveButton">
                        <sui-button
                            ui
                            fluid
                            button
                            v-on:click="saveProject"
                            :disabled="loading"
                            :loading="loading"
                        >
                            {{ $t("result.productsList.saveProject.saveButton") }}
                        </sui-button>
                    </div>
                    <div class="four wide column center aligned downloadButton">
                        <sui-button
                            ui basic fluid button
                            v-on:click="generateProductsList"
                            :disabled="loading"
                            :loading="loading"
                        >
                            {{ $t("result.productsList.saveProject.pdfButton") }}
                        </sui-button>
                    </div>
                </div>
            </div>
        </sui-container>
        <sui-modal v-model="projectNameModal" size="mini">
            <sui-modal-content>
                <div class="modal-top-bar">
                    <div class="text">{{ $t("result.productsList.modal.headError") }}</div>
                    <div class="cross" v-on:click="closeModal('name')">x</div>
                </div>
                <p>
                    {{ $t("result.productsList.modal.contentError") }}
                </p>
            </sui-modal-content>
        </sui-modal>
        <sui-modal v-model="projectPDFModal" class="pdfModal">
            <sui-modal-content>
                <div class="modal-top-bar">
                    <div class="text">{{ $t("result.productsList.modal.headDownload") }}</div>
                    <div class="cross" v-on:click="closeModal('pdf')">x</div>
                </div>
                <p>{{ $t("result.productsList.modal.contentThanksDownload") }}</p>
                <p>
                    {{ $t("result.productsList.modal.contentDownload") }}
                </p>
                <div class="ui centered grid">
                    <div class="row">
                        <div class="ten wide computer sixteen wide mobile column">
                            <sui-button fluid v-on:click="displayPDF()">
                                {{ $t("result.productsList.modal.buttonDownload") }}
                            </sui-button>
                        </div>
                    </div>
                </div>
                <!-- <p><a v-on:click="displayPDF()" style="cursor:pointer">Kliknij tutaj</a> aby pobrać PDF-a z listą produktową.</p> -->
            </sui-modal-content>
            <sui-modal-actions>
                <p>
                    {{ $t("result.productsList.modal.contentRemember") }}
                </p>
                <div class="ui centered grid">
                    <div class="row">
                        <div class="five wide sixteen wide mobile column ">
                            <sui-button
                                fluid
                                v-on:click="saveProjectInModal()"
                                :disabled="loading"
                                :loading="loading"
                            >{{ $t("result.productsList.modal.buttonRemember") }}
                            </sui-button
                            >
                        </div>
                    </div>
                </div>
            </sui-modal-actions>
        </sui-modal>

        <sui-modal v-model="projectSavedModal" size="mini">
            <sui-modal-content>
                <div class="modal-top-bar">
                    <div class="text">{{ $t("result.banner.modal.saved") }}</div>
                    <div class="cross" v-on:click="closeModal('saved')">x</div>
                </div>
                <h4 style="color: rgba(0,0,0, 0.87);">
                    {{ $t("result.banner.projectSaved") }}
                </h4>
                <p style="color: rgba(0,0,0, 0.87);">
                    {{ $t("result.banner.modal.nowYouCanGo") }}
                    <strong
                    >
                        <router-link style="color: rgba(0,0,0, 0.87);" to="profile"
                        >{{ $t("result.banner.modal.profile") }}
                        </router-link
                        >
                    </strong
                    >
                    {{ $t("result.banner.modal.toSeeProjects") }}.
                </p>
            </sui-modal-content>
        </sui-modal>
    </div>
</template>

<script>
import VuePureLightbox from "vue-pure-lightbox";
import {mapGetters} from "vuex";
// eslint-disable-next-line no-unused-vars
import styles from "vue-pure-lightbox/dist/VuePureLightbox.css";

import {mapState} from "vuex";

export default {
    name: "ProductsList",
    components: {
        VuePureLightbox
    },
    data() {
        return {
            priceEnable: false,
            currency: this.$store.state.settings.currency,
            priceSettings: this.$store.state.settings.priceEnable,
            saveButton: false,
            products: this.$store.state.rooms.products,
            loggedIn: this.$store.state.users.isLoggedIn,
            projectNameModal: false,
            projectPDFModal: false,
            projectSavedModal: false,
            projectData: {
                name: "",
                street: "",
                city: "",
                zipCode: "",
                investor: ""
            },
            isComponentLoaded: false,
            removedOrOptionalProducts: this.$store.state.rooms.optionalProducts,
        };
    },
    computed: {
        ...mapGetters({
            getPricesSum: "rooms/getPricesSum"
        }),

        ...mapState({
            loading: state => {
                return state.orders.loading;
            }
        }),

        loggedInChanged() {
            return this.$store.state.users.isLoggedIn;
        },
        productsChanged() {
            return this.$store.state.rooms.products;
        },
        optionalProductsChanged() {
            return this.$store.state.rooms.optionalProducts;
        },
        projectNameChanged() {
            return this.$store.state.orders.projectName;
        }
    },
    watch: {
        products: function () {
            if (this.products.length > 0) {
                setTimeout(function () {
                    window.scrollBy(0, window.screen.height - 310);
                }, 500);
            }
        },
        loggedInChanged(value) {
            this.loggedIn = value;
        },
        productsChanged(value) {
            this.products = value;
        },
        optionalProductsChanged(value) {
            this.removedOrOptionalProducts = value;
        },
        projectNameChanged(value) {
            this.projectData.name = value;
            if (value) {
                this.saveButton = true;
            } else {
                this.saveButton = false;
            }
        }
    },
    methods: {
        recalculateMufs: function (isMuff) {
            if(isMuff) {
                this.$store.state.rooms.muffChanged = true;
            }

            if(!isMuff && !this.$store.state.rooms.muffChanged) {
                let eduroMufImpact = [
                    'trojnik',
                    'rura-prosta-dlugosc-1m',
                    'przepustnica',
                    'kolano-45',
                    'redukcja',
                    'tlumik-aduro'
                ];

                let mufAmount = 0;

                this.products.forEach(val => {
                    if (val.hasOwnProperty('slug') && eduroMufImpact.includes(val.slug)) {
                        mufAmount += val.quantity;
                    }
                });

                this.products.forEach((val, key) => {
                    if (val.hasOwnProperty('slug') && val.slug === 'mufa') {
                        if(mufAmount >= 2) {
                            this.products[key].quantity = parseInt(mufAmount - 2);
                        } else {
                            this.products[key].quantity = 0;
                        }

                        let newPrice = this.products[key].quantity * parseFloat((this.products[key].price).replace(',', '.'));
                        if (newPrice && this.products[key].price) {
                            newPrice = newPrice.toFixed(2).toString().replace('.', ',');

                            this.products[key].price_sum = newPrice;
                        }
                    }
                });

                this.$store.state.rooms.products = this.products;
            }
        },
        itemQuantity: function (idx) {
            let newPrice = this.products[idx].quantity * parseFloat((this.products[idx].price).replace(',', '.'));
            if (newPrice && this.products[idx].price) {
                newPrice = newPrice.toFixed(2).toString().replace('.', ',');

                this.products[idx].price_sum = newPrice;
            }
            this.$store.state.rooms.products = this.products;

            this.recalculateMufs(this.products[idx].slug === 'mufa');
        },
        removeProduct: function (idx) {
            let isMuff = this.products[idx].slug === 'mufa';

            this.removedOrOptionalProducts.push(this.products[idx]);

            this.products.splice(idx, 1);

            this.$store.state.rooms.products = this.products;

            this.recalculateMufs(isMuff);
        },
        addProduct: function (idx) {
            let isMuff = this.products[idx].slug === 'mufa';

            this.products.push(this.removedOrOptionalProducts[idx]);

            this.removedOrOptionalProducts.splice(idx, 1);

            this.$store.state.rooms.products = this.products;

            this.recalculateMufs(isMuff);
        },
        priceChange: function () {
            this.$store.state.rooms.priceEnable = this.priceEnable;
        },
        saveProjectInModal: function () {
            this.closeModal("pdf");
            this.saveProject();
        },
        closeModal: function (modal) {
            switch (modal) {
                case "pdf":
                    this.projectPDFModal = false;
                    break;
                case "name":
                    this.projectNameModal = false;
                    break;
                case "saved":
                    this.projectSavedModal = false;
                    break;
                default:
                    break;
            }
        },
        isOptional(product) {
            if (!product.required) {
                return true;
            }
        },
        generateProductsList() {
            let params = this.$store.state.rooms.orderData;
            params.pdf = true;
            this.$store.dispatch("orders/saveProject", params).then(response => {
                this.$store.state.rooms.order = response.data;
                this.projectPDFModal = true;
            });
        },
        toggleProduct() {
            this.$store.dispatch("rooms/setProducts", this.products);
        },
        saveProject() {
            if (!this.loggedIn) {
                this.$store.dispatch("users/toggleModal", true);
            } else {
                let projectName = this.$store.state.orders.projectName;
                let street = this.$store.state.orders.street;
                let city = this.$store.state.orders.city;
                let postcode = this.$store.state.orders.postcode;
                let investor = this.$store.state.orders.investor;

                if (!projectName) {
                    this.projectNameModal = true;
                    window.scrollTo(0, 0);
                } else {
                    this.projectData.name = projectName;
                    this.projectData.street = street;
                    this.projectData.city = city;
                    this.projectData.zipCode = postcode;
                    this.projectData.investor = investor;
                    this.$store
                        .dispatch("orders/saveProject", this.projectData)
                        .then(() => {
                            this.projectSavedModal = true;
                            this.projectNameModal = false;
                        });
                }
            }
        },
        displayPDF() {
            if (!this.loggedIn) {
                this.$store.dispatch("users/toggleModal", true);
                this.projectPDFModal = false;
            } else {
                this.$store.dispatch("rooms/generateProductsList");
            }
        }
    }
};
</script>

<style>
input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
    height: 36px;
}

.ui.input > input[type=number] {
    text-align: center;
    padding: 0.5em 0 0.5em 0 !important;
}
</style>