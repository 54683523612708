<template>
  <div>
    <Project />
  </div>
</template>

<script>
import Project from "@/components/Project.vue";
export default {
  name: "Resultpage",
  components: {
    Project
  }
};
</script>
